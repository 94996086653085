const openPopupCenter = (url, title, w, h) => {
  // Fixes dual-screen position                         Most browsers      Firefox
  let dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
  let dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

  let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
  let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

  // let systemZoom = width / window.screen.availWidth
  let systemZoom = 1
  let left = (width - w) / 2 / systemZoom + dualScreenLeft
  let top = (height - h) / 2 / systemZoom + dualScreenTop
  // console.log(window)
  // alert(window)
  let newWindow = window.open(url, title, 'scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left)
  //
  // // Puts focus on the newWindow
  if (window.focus) newWindow.focus()
  return newWindow
}

export {
  openPopupCenter
}
