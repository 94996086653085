const LogoConstants = {
  'bee_market': 'https://lh3.googleusercontent.com/pw/AM-JKLVNtc0FPun7QyhteOp63hNMqllVpRf9yMCOWg6kx890HNriUmElw3eRpZIA8Q8d5DT30DmcenqDWt6KY3dZOFw8ffmtI7_gn2rb1b_pgCyfWZsIRE5Jpyzx9NJQRl_dCuYm6yNDC0QbXn0NQevDmoV1',
  'bee_metric': 'https://lh3.googleusercontent.com/pw/AM-JKLUTsd1ZVvXmDGTlWL0Z6lfrZ0IO7UqK9PbYUlk3nfTUT3HUUKyQ0PXdCh7htGl1qk11YHkjhBL0kbdZRzTM1OIaeoryhDJwIJOgNHIgiWkPtAbjFgXhcLoYjz7SnUyv4q7hxgZo3ZwZ2yoVCq23Dxw=w976-h384-no',
  'bee_metric_slogan': 'https://lh3.googleusercontent.com/pw/AM-JKLWNGcC8SsEfwvpJO5GAeV82CbIWbUGWUp7GB2TrJ_Xr3uuBAc2cZUan6IeZtBnA58YFqltIQsHbMOWmLjwMhzugOEuQgupgPQsw7Fttz9yol4Lp5KLQR5s4xULtYAive09-munQ9-oX-QmOTE2sdw8=w1609-h461-no'
}

const StickerConstants = {
  'hi': 'https://lh3.googleusercontent.com/4uPs4CrPGITEvTn91Ad0EZH9UlhkXGKt660RlPdbJnM30tkF0mYEJeHkjphYJRn6FlMEax9EWC_1tkFeyrYRvGsSCOPIUX2NgwBxvSUGW94DmJevH_MNkpVZdxdJmSEacjIDA9LfBA',
  'chao_dong_chi': 'https://lh3.googleusercontent.com/w_XmRx8Wr9YjT5wvowD0_IupgNUQ0T9WlBZk2SL_PiYIwn6UsqbbVXyF3U3zkY4UzpJoXQDXmdNre6u60pHu1ivGrXbFMMrYBXuiKhTKIF2qn4vmECs8Utrz14OsRjhX4u1aq0yrRg',
  'search': 'https://lh3.googleusercontent.com/n7c_MkwweUmIzz-F61wA2vfwbHddHacI32MDbe9MEFUPjKXopdzRBv28Mady21RXcVB6FrvN3NihkCHUjrLxLJbIQOxCoRYoE43lvfuyc9YgHMGbUWdJk2WDS2djJ6Fns6rCdOSQHg',
  'ask': 'https://lh3.googleusercontent.com/QiTbve7leB_60j87cLEkfdL_i-uaKfFK2aZVbgnqQByeLQYUYCn5jMBwPvagV6dGPFn8IpImJEB6q_L5Bwb2hmj1EiST7QBjPn5gd1ieHOuTCvcwp239Z5dtQJrK_UzGSvxhkdk_mA',
  'introduction': 'https://lh3.googleusercontent.com/aD0ejhBo2NwwG-dlpLHL90ORd8Pe3gcoUdtuR94t6b72f336b2kqVPNHgIblj5s-2P7WpkCUXu_JHxzkw0K9PnB9wnH-Q9uIdwpKT7ZIbefi3tUbYodTX67q7xEMiyNmhw2_AcZlzA',
  search2: 'https://lh3.googleusercontent.com/pw/AM-JKLXlmfJgMmDBtsN2H4d_R17rHjwo6ne_xtDQU2oa6dMOliNnQ7UiMja-c7CwHy1Q6jUtJSuX8aAX3DJfS8ADa690s9wgbroTuQXEW2pjttD-ii0atsDPTW1L-7AOyMFPjIii9hP9SuM8ksEvrB32R-KI=w996-h1090-no'
}

const Message = {
  USER_LOGGED_IN: 'User logged in'
}

export {
  StickerConstants,
  LogoConstants,
  Message
}
