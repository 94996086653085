<template>
  <div>
    <component :is="'script'" src="https://accounts.google.com/gsi/client" async defer></component>
    <div id="g_id_onload" data-client_id="426000576581-0o4c24jbbcmi9mkiqs1kh87p50ki5f7n.apps.googleusercontent.com"
         data-context="signin" data-ux_mode="popup" data-callback="handleCredentialResponse" data-auto_prompt="false">
    </div>
    <div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="outline" data-text="signin_with"
         data-size="large" data-logo_alignment="left"></div>
  </div>
</template>

<script>
export default {
  name: "GoogleLoginButton"
}
</script>

<style scoped></style>
