<template>
  <div class="page-login">
    <div class="page-login-header">
      <a href="/"><BeeMetricLogoSlogan /></a>
    </div>
    <div class="page-login-body">
      <div class="title">Đăng nhập</div>
      <div class="text-xl font-normal">
        Đăng nhập tài khoản để sử dụng tính năng này.
      </div>
      <div class="google-login-button-wrapper">
        <GoogleLoginButton />
      </div>
      <div class="text-base">
        Bằng việc Đăng nhập/Đăng ký, bạn đồng ý với
        <a href="/terms" target="_blank">
          Điều khoản sử dụng
        </a>
        của Metric
      </div>
    </div>
    <img
      src="https://lh3.googleusercontent.com/pw/AM-JKLVTAzEEwKlZbJE2lkEkQYs2yGa0ycjrl8BUrF1POrJ_mZ9_xz-1bTVx6Gomq3JOrLhe9YwSJls9npD3vGjcxrrKOtLnqrZxyj3l8FxcDND0Qp4kReoNTzCC9NeHbeA4JH2N9RZMR3rFSlFGOSJxAMc=w1216-h1438-no"
      class="login-background-image"
    />
  </div>
</template>

<script>
import BeeMetricLogoSlogan from "@/assets/logo/BeeMetricLogoSlogan";
import GoogleLoginButton from "@/components/button/GoogleLoginButton";
import { openPopupCenter } from "@/helper/WindowHelper";
import { SocialLoginURIs } from "@/api/user/UserEndpoint";
import { Message } from "@/constant/beecost/BeeCostResourceConstant";
import { updateProfileUserCurrent } from "@/service/user/UserProfileService";

export default {
  components: {
    BeeMetricLogoSlogan,
    GoogleLoginButton
  },
  methods: {
    async onClickGoogleLogin() {
      const receiveMessage = (event) => {
        if (event.data !== Message.USER_LOGGED_IN) {
          return;
        }
        updateProfileUserCurrent().then((userProfile) => {
          console.log("userProfile", userProfile);
          window.removeEventListener("message", receiveMessage, false);
          newWindow.close();

          location.href = "/analytics";
        });
      };

      const newWindow = openPopupCenter(SocialLoginURIs.GOOGLE, "", 600, 600);
      window.addEventListener("message", receiveMessage, false);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-login {
  padding: 0;

  background: linear-gradient(180deg, #ffc894 0%, rgba(247, 175, 107, 0) 100%);
  color: #1e3d59;

  .page-login-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 146px;

    background-image: url("https://lh3.googleusercontent.com/pw/AM-JKLVwmZZqenw3sdH2-4q--ygttzkPzpSBEBnlCV09TCWrcRoRlicgC2ur4Tt07pgrBWHsPNBZl0F5WFPilWHpFbi6FlpzmHWzYPGCb27VBhNw4xkcKqKyT2ohHhCYijJto3BuYGzxA93MLBJKTjPz3_4=w375-h146-no");
    background-size: cover;
    svg {
      width: 280px;
      height: 75px;
    }
  }

  .page-login-body {
    padding: 24px;
  }

  .title {
    margin-top: 40px;
    margin-bottom: 16px;

    font-weight: 500;
    font-size: 36px;
    color: #050038;
  }

  .btn-login {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 60px;

    margin-top: 34px;
    margin-bottom: 32px;
    padding: 19px 0;

    font-size: 16px;
    font-weight: 500;

    background: white;
    border: 1px solid #ff7227;
    border-radius: 12px;
    box-shadow: 2px 7px 12px rgba(164, 164, 164, 0.17);

    cursor: pointer;

    .img-login-social {
      width: 19px;
      margin-right: 8px;
    }
  }

  .login-background-image {
    display: none;
  }
}

@media (min-width: 576px) {
  .page-login {
    padding: 40px 600px 0 200px;

    .page-login-header {
      display: block;
      height: unset;

      background: transparent;

      svg {
        width: 290px;
        height: 76px;
      }
    }
    .page-login-body {
      padding: 0;
    }

    .title {
      margin-top: 100px;
      margin-bottom: 24px;

      color: #050038;
    }

    .btn-login {
      width: unset;
      padding: 19px 80px;

      font-size: 20px;

      .img-login-social {
        width: 30px;
        margin-right: 12px;
      }
    }

    .login-background-image {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }
  }
}
</style>
